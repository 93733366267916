import React from "react";
import {
  RouterProvider
} from "react-router-dom";

//Dark mode
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Parasail imports
import "App.css";
import { RoutesPaths } from "utils/CommonConsts";

function App() {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // const theme = React.useMemo(
  //   () =>
  //     createTheme({
  //       palette: {
  //         mode: prefersDarkMode ? 'dark' : 'light',
  //       },
  //     }),
  //   [prefersDarkMode],
  // );

  return (
    <>
    {/* <ThemeProvider theme={theme}> */}
      <CssBaseline />
      <RouterProvider router={RoutesPaths} />
    {/* </ThemeProvider> */}
    </>
  );
}

export default App;
