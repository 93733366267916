import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { useRouteError, useNavigate, useSearchParams } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';

// Parasail imports
import { useUserContext } from "components/providers/UserContext";
import 'styles/LoginView.css';


const LoginView = () => {
  const [showLoginErrorMessage, setShowLoginErrorMessage] = React.useState(false);
  const [isInvite, setIsInvite] = React.useState(false);
  const [isInviteFailure, setIsInviteFailure] = React.useState(false);
  const [inviteFailureReason, setInviteFailureReason] = React.useState('');

  const { UserContextService, UserState } = useUserContext();

  // get any params from url
  const [searchParams] = useSearchParams();

  const mobile = useMediaQuery('(max-width:400px)');
  const tablet = useMediaQuery('(max-width:1000px)');
  const routeError = useRouteError();
  const navigate = useNavigate();

  // If the user is authenticated, route them to the home page. 
  console.log('LoginView - UserState.isAuthenticated: ', UserState.isAuthenticated);
  if(UserState.isAuthenticated) {
    console.log('LoginView -go to /home...');
    navigate("/home", { replace: true });
  }

  React.useEffect(() => {
    const isInviteParam = searchParams.size !== 0 && searchParams.get("invite");
    const isInviteFailureParam = searchParams.size !== 0 && searchParams.get("inviteFailure");
    const inviteFailureReasonParam = searchParams.size !== 0 && searchParams.get("inviteFailureReason");

    console.log('isInviteParam', isInviteParam);

    if(isInviteParam && isInviteParam.length !== 0) {
      setIsInvite(isInviteParam === 'true' ? true : false);
    }
    if(isInviteFailureParam && isInviteFailureParam.length !== 0) {
      setIsInviteFailure(isInviteFailureParam === 'true' ? true : false);
    }
    if(inviteFailureReasonParam && inviteFailureReasonParam.length !== 0) {
      setInviteFailureReason(inviteFailureReasonParam);
    }
  }, [searchParams]);

  React.useEffect(() => {

    // confirm if user is authtneticated
    // TODO: move to user context?
    if(routeError || (searchParams.get("isSessionExpired") && searchParams.get("isSessionExpired") === 'true')) {
      setShowLoginErrorMessage(true);
      UserContextService.clearUserState();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeError, searchParams]);

  return (
    <>
      <Grid container spacing={0}>
        <Grid size={{ xs: 3, md: 3 }} className="login-left-panel">
          <div className="login-left-panel-title" style={{position: "relative", minHeight: "100vh"}}>
            <Box sx={{ fontSize: 14 }} className="text-white">
              <Stack direction="column" spacing={1} alignItems="center">
                { isInvite && (
                  <Alert severity="success" className='mb-2'>
                    Your invite link is valid.
                  </Alert>
                )}
                {isInviteFailure && (
                  <Alert severity="warning" className='mb-2'>
                    Your invite link is invalid. {inviteFailureReason}
                  </Alert>
                )}
                <Typography variant="h6" className='text-white'>
                  {showLoginErrorMessage ? "Please sign up or sign in" : tablet || mobile ? "Login" : "Please sign up or sign in"}
                </Typography>
                <button onClick={() => window.location.assign("/oauth2/authorization/auth0user")} className="gsi-material-button" style={{width: mobile ? "64px" : tablet ? "100px" : "250px", height: "50px"}}>
                  <div className="gsi-material-button-state"></div>
                  <div className="gsi-material-button-content-wrapper">
                    <div className="gsi-material-button-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><path d="M49.012 51.774L42.514 32l17.008-12.22h-21.02L32.005 0h21.032l6.506 19.78c3.767 11.468-.118 24.52-10.53 31.993zm-34.023 0L31.998 64l17.015-12.226-17.008-12.22zm-10.516-32c-3.976 12.1.64 24.917 10.5 32.007v-.007L21.482 32 4.474 19.774l21.025.007L31.998 0H10.972z" fill="#eb5424"/></svg>
                    </div>
                    <span className="gsi-material-button-contents">{mobile ? "" : tablet ? "Email" : "Continue with Email"}</span>
                    <span style={{display: "none"}}>Continue with Email</span>
                  </div>
                </button>

                <button onClick={() => window.location.assign("/oauth2/authorization/googleuser")} className="gsi-material-button" style={{width: mobile ? "64px" : tablet ? "100px" : "250px", height: "50px"}}>
                  <div className="gsi-material-button-state"></div>
                  <div className="gsi-material-button-content-wrapper">
                    <div className="gsi-material-button-icon">
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlnsXlink="http://www.w3.org/1999/xlink" style={{display: "block"}}>
                        <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                        <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                        <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                        <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                        <path fill="none" d="M0 0h48v48H0z"></path>
                      </svg>
                    </div>
                    <span className="gsi-material-button-contents">{mobile ? "" : tablet ? "Google" : "Continue with Google"}</span>
                    <span style={{display: "none"}}>Continue with Google</span>
                  </div>
                </button>
                
                {mobile || tablet ? 
                  <div style={{position: "fixed", bottom: "0", display: "flex", flexDirection: "column" }}>
                    <span style={{marginRight: "10px"}}><a href="/assets/privacy-policy.html" rel="noreferrer" className="hover:underline ">Policy</a></span>
                    <span style={{marginRight: "10px"}}><a href="/assets/about.html" rel="noreferrer" className="hover:underline ">About</a></span>
                    <span><a href="https://parasail.io/" className="hover:underline">Parasail</a></span>
                  </div>
                :
                  <div style={{position: "fixed", bottom: "0", display: "flex", flexDirection: "row"}}>
                    <span style={{marginRight: "10px"}}><a href="/assets/privacy-policy.html" rel="noreferrer" className="hover:underline ">Privacy Policy</a></span>
                    <span style={{marginRight: "10px"}}><a href="/assets/about.html" rel="noreferrer" className="hover:underline ">About</a></span>
                    <span>© 2024 <a href="https://parasail.io/" className="hover:underline">Parasail</a></span>
                  </div>
                } 
              </Stack>
            </Box>
          </div>
        </Grid>
        <Grid size={{ xs: 9 }} className="login-right-panel">
          <div className="login-right-panel-title" style={{position: "relative", minHeight: "100vh"}}>
            <div className="company-text">
              <h2>PARASAIL</h2>
            </div>
            <p className={mobile ? "sub-text--small " : "sub-text "}>Secure on-demand GPU inference</p>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginView;