import {
  createBrowserRouter,
  createRoutesFromElements,
  Route, 
  Navigate,
  Outlet,
  useLocation,
  useRouteError,
  useNavigate,
  isRouteErrorResponse
} from "react-router-dom";

import Button from '@mui/material/Button';
import LayoutPlain from "components/LayoutPlain";
import Layout from "components/Layout";
import LoginView from "components/views/LoginView";
import SettingsView from "components/views/SettingsView";
import InviteCreateView from "components/views/InviteCreateView";
import ServerlessHomeView from "components/views/ServerlessHomeView";
import DedicatedHomeView from "components/views/DedicatedHomeView";
import DedicatedCreateView from "components/views/DedicatedCreateView";
import HomeView from "components/views/HomeView";
import HomeAuthenticatedOnlyView from "components/views/HomeAuthenticatedOnlyView";
import KeysView from "components/views/KeysView";
import GeneralKeysView from "components/views/GeneralKeysView";
import BillingView from "components/views/BillingView";
import BatchHomeView from "components/views/BatchHomeView";
import PriceView from "components/views/PriceView";
import DocsView from "components/views/DocsView";
import SignupView from "components/views/SignupView";

import { 
  FetchServerlessAPI, 
  FetchDedicatedAPI, 
  FetchCommonAPI, 
  FetchBatchAPI,
  FetchKeyAPI
} from "service/FetchService";
import { DedicatedDeployment } from "react-app-env";

// Parasail imports
import PageHeader from "components/PageHeader";
import { Logger } from "utils/Logger";
import { useUserContext } from "components/providers/UserContext";

const NoMatch = () => {
  let location = useLocation();
  const navigate = useNavigate();

  Logger.log('location: ', location);

  return (
    <div className="m-6">
      <PageHeader title={`No match for ${location.pathname}`} width="0"/>
      {/* <h3>
        No match for <code>{location.pathname}</code>
      </h3> */}
      <div className="pt-3">
        <div className="text-default mb-3">Please click to go back to the main page.</div>
        <Button onClick={() => navigate('/home')} variant="contained">Back</Button>
      </div>
    </div>
  )
}

interface ErrorProps {
  error?: any,
  customErrorType?: String
}
const Error: React.FC<ErrorProps>  = ({error, customErrorType}) => {
  Logger.log('isRouteErrorResponse? ', isRouteErrorResponse(error), customErrorType); //True if route error response
  const navigate = useNavigate();
  let errorTitle = "Something went wrong";//500
  let buttonText = 'Back to login';
  let navUrl = '/';
  let errorMessage = 'Please try to login again.';

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      errorTitle = "This page doesn't exist!";
    }

    if (error.status === 401) {
      errorTitle = "You aren't authorized to see this";
    }

    if (error.status === 503) {
      errorTitle = "Looks like our API is down";
    }

    if (error.status === 418) {
      errorTitle = "An error (418) occurred";
    }
  }

  // custom error types
  if (customErrorType === 'dedicated-id-error') {
    errorTitle = 'Invalid dedicated url';
    buttonText = 'Back to Dedicated'
    navUrl = '/dedicated';
    errorMessage = 'The dedicated url is not valid or you do not have access.';
  }

  return (
    <div>
      {/* <h1>{error.status}</h1> */}
      {/* <h1>{errorTitle}</h1> */}
      <PageHeader title={errorTitle} width="0"/>
      <p className="pt-3">
        <pre className="text-default mb-3">{ error.message && !customErrorType ? error.message : errorMessage }</pre>
        <Button onClick={() => navigate(`${navUrl}`)} variant="contained" style={{textTransform :"none"}}>{buttonText}</Button>
      </p>
    </div>
  );
}


interface ErrorBoundaryProps {
  customErrorType?: String
}

// see: https://reactrouter.com/en/main/route/error-element#errorelement
const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({customErrorType}) => {
  let error = useRouteError();
  Logger.error('RouteError: ', error);

  // Uncaught ReferenceError: path is not defined
  return <Error error={error} customErrorType={customErrorType} />;
}

// For authenticated routes
const AuthenticatedRoutes = () => {
  // Check authentication 
  const { UserState, isUserContextLoading } = useUserContext();
  Logger.log('AuthenticatedRoutes called', UserState.isAuthenticated, UserState.isAuthorized, isUserContextLoading)

  if( !isUserContextLoading){
    if(UserState.isAuthenticated) {
      // check if user is authorized and redirect to home
      if(UserState.isAuthorized) {
        return <Navigate to="/home" />;
      } else {
        return <Outlet />;
      }
    } 
    else {
      return <Navigate to="/login" />;
    }
  } else {
    return <></>;
  }
};

// For authorized routes (all authorized routes should also be authenticated)
const AuthorizedRoutes = () => {
  // Check authentication 
  const { UserState, isUserContextLoading } = useUserContext();

  Logger.log('AuthorizedRoutes called', UserState.isAuthenticated, UserState.isAuthorized, isUserContextLoading)
  if( !isUserContextLoading){
    if(UserState.isAuthenticated) {
      // check if user has access to view intended route
      if(UserState.isAuthorized) {
        return <Outlet />;
      } else {
        return <Navigate to="/new" />;
      }
    } 
    else {
      return <Navigate to="/login" />;
    }
  } else {
    return <></>;
  }
};

export const RoutesPaths = createBrowserRouter(
  createRoutesFromElements(
      <Route path="/" element={<LayoutPlain />} >

        {/* Public routes */}
        <Route index path="/" element={<LoginView />} />
        <Route index path="/login" element={<LoginView />} />


        {/* Authenticated routes */}
        <Route path='/' element={<AuthenticatedRoutes/>}>
            <Route element={<Layout />}>
              <Route
                path="/new"
                element={<HomeAuthenticatedOnlyView />}
              />
              <Route
                path="/signup"
                element={<SignupView />}
              />
            </Route>
        </Route>

        {/* Authorized routes */}
        <Route path='/' element={<AuthorizedRoutes/>}>
          <Route element={<Layout />}>
            <Route
              path="/home"
              element={<HomeView />}
            />
            <Route
              path="/invite"
              element={<InviteCreateView />}
            />
            <Route
              path="/serverless"
              element={<ServerlessHomeView />}
              errorElement={<ErrorBoundary />}
              loader={FetchServerlessAPI.getDeployments}
              shouldRevalidate={() => true}
            />
            <Route
              path="/dedicated"
              element={<DedicatedHomeView />}
              errorElement={<ErrorBoundary />}
              loader={async () => {
                Logger.log('/dedicated loader: ');
                const res1 = await FetchDedicatedAPI.getDeployments();
                const deployments = res1 as DedicatedDeployment[];
            
                return { deployments };
              }}
              shouldRevalidate={() => true}
            />
            <Route
              path="/dedicated/:deploymentId"
              element={<DedicatedHomeView />}
              errorElement={<ErrorBoundary customErrorType="dedicated-id-error" />}
              loader={async ({ params }) => {
                Logger.log('params.deploymentId from loader: (/dedicated/:deploymentId) ', params.deploymentId)
                if (params.deploymentId) {
                  // it is possible this deploymentId is not valid for this user and we will throw an error here
                  const res1 = await FetchDedicatedAPI.getDeployment(params.deploymentId);
                  const deployment = res1 as DedicatedDeployment;

                  const res2 = await FetchDedicatedAPI.getDeployments();
                  const deployments = res2 as DedicatedDeployment[];
              
                  return { deployment, deployments };
                }
              }}
              shouldRevalidate={() => true}
            />
            <Route
              path="/batch"
              element={<BatchHomeView />}
              errorElement={<ErrorBoundary />}
              loader={async () => {
                Logger.log('/batch loader: ');

                // it is possible this deploymentId is not valid for this user and we will throw an error here
                const res1 = await FetchBatchAPI.getUsages();
                const batchUsageGeneral = (res1 as any)["general"];
                const batchUsageComplete = (res1 as any)["complete"];
                const batchUsageCreateInput = (res1 as any)["create_input"];
                const batchUsageSubmit = (res1 as any)["submit"];
                const batchUsageCheckStatus = (res1 as any)["check_status"];
                const batchUsageRetrieve = (res1 as any)["retrieve"];

                return { batchUsageGeneral, batchUsageComplete, batchUsageCreateInput, batchUsageSubmit, batchUsageCheckStatus, batchUsageRetrieve };
              }}
              shouldRevalidate={() => true}
            />
            <Route
              path="/keys"
              element={<GeneralKeysView />}
              errorElement={<ErrorBoundary />}
              loader={FetchKeyAPI.getKeys}
              shouldRevalidate={() => true}
            />
            <Route
              path="/billing"
              element={<BillingView />}
              errorElement={<ErrorBoundary />}
            />
            <Route
              path="/dedicated/new"
              element={<DedicatedCreateView />}
              errorElement={<ErrorBoundary />}
            />
            <Route
              path="/dedicated/edit"
              element={<DedicatedCreateView />}
              errorElement={<ErrorBoundary />}
            />
            <Route
              path="/dedicated/:id/keys"
              element={<KeysView />}
              errorElement={<ErrorBoundary />}
              loader={FetchDedicatedAPI.getDeploymentKeys}
              shouldRevalidate={() => true}
            />
            <Route
              path="/settings"
              element={<SettingsView />}
              errorElement={<ErrorBoundary />}
              loader={FetchCommonAPI.getAccounts}
              shouldRevalidate={() => true}
            /> 
            <Route
              path="/price"
              element={<PriceView />}
              errorElement={<ErrorBoundary />}
              shouldRevalidate={() => true}
            />
            <Route
              path="/docs"
              element={<DocsView />}
              errorElement={<ErrorBoundary />}
              loader={async ({ params }) => {
                Logger.log('/batch loader: ', params);

                // it is possible this deploymentId is not valid for this user and we will throw an error here
                const res1 = await FetchBatchAPI.getUsages();
                const batchUsageCreateInput = (res1 as any)["create_input"];
                const batchUsageSubmit = (res1 as any)["submit"];
                const batchUsageCheckStatus = (res1 as any)["check_status"];
                const batchUsageRetrieve = (res1 as any)["retrieve"];

                return { batchUsageCreateInput, batchUsageSubmit, batchUsageCheckStatus, batchUsageRetrieve };
              }}
              shouldRevalidate={() => true}
            />
            <Route
              path="/error"
              element={<Error />}
              errorElement={<ErrorBoundary />}
            />
          </Route>
        </Route>

        <Route
          path="/error"
          element={<Error />}
          errorElement={<ErrorBoundary />}
        />
        <Route 
          path="*"
          element={<NoMatch />}
          errorElement={<ErrorBoundary />}
        />
      </Route>
    ),
  );